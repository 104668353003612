<inthraction-page-header pageTitle="Organization Survey Statistics"></inthraction-page-header>
<mat-form-field class="half-width">
  <mat-label>Start Date</mat-label>
  <input [matDatepicker]="startDatePicker" matInput [(ngModel)]="startDate" >
  <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
  <mat-datepicker #startDatePicker></mat-datepicker>
</mat-form-field>
<div class="wrapper mat-elevation-z8" [hidden]="!tableData.data.length">
  <mat-table matSort *ngIf="tableData" [dataSource]="tableData">
    <ng-container matColumnDef="organizationName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.organizationName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inthractionCountConfig">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Organization Configuration for number of intHRaction before an Objective Survey is sent">intHRaction Count</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inthractionCountConfig}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activeEmployees">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Users</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.activeEmployees}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventSurveyConfig">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Organization Configuration for sending intHRaction surveys"> intHRactions Enabled</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.eventSurveyConfig}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inthractionExpirationConfig">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Organization Configuration for number of days until an intHRaction survey expires"> intHRaction Expiration</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inthractionExpirationConfig}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="objectiveExpirationConfig">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Organization Configuration for number of days until an Objective survey expires"> Objective Expiration</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.objectiveExpirationConfig}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="intHRactionEmployeesSurveyed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Number of unique users sent an intHRaction survey in the selected date range"> Users Surveyed: intHRactions</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.intHRactionEmployeesSurveyed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="intHRactionSurveysSent30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total intHRaction surveys sent in the selected date range"> Surveys: intHRaction</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.intHRactionSurveysSent30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="intHRactionSurveysMissed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total Missed intHRaction surveys in the selected date range"> Missed intHRaction</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.intHRactionSurveysMissed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="intHRactionSurveyParticipants30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Number of unique users for which a intHRaction survey was about in the selected date range"> Users Inquired: intHRaction</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.intHRactionSurveyParticipants30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="objectiveEmployeesSurveyed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Number of unique users sent an Objective survey in the selected date range"> Users Surveyed: Objective</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.objectiveEmployeesSurveyed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="objectiveSurveysSent30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total Objective surveys sent in the selected date range">Objective Surveys: Objective</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.objectiveSurveysSent30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="objectiveSurveysMissed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total Missed Objective surveys in the selected date range"> Missed Objective</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.objectiveSurveysMissed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="objectiveSurveyParticipants30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Number of unique users for which an Objective survey was about in the selected date range"> Users Inquired: Objective</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.objectiveSurveyParticipants30}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="inspHRationEmployeesSurveyed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Number of unique users sent an inspHRation survey in the selected date range">Users Surveyed: inspHRation</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inspHRationEmployeesSurveyed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inspHRationSurveysSent30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total inspHRation surveys sent in the selected date range">inspHRation Surveys</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inspHRationSurveysSent30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inspHRationSurveysMissed30">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total Missed inspHRation surveys in the selected date range">Missed inspHRation</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inspHRationSurveysMissed30}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="inspHRationSurveysGiven">
      <mat-header-cell *matHeaderCellDef mat-sort-header title="Total inspHRations given in the selected date range">Given inspHRations</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.inspHRationSurveysGiven}} </mat-cell>
    </ng-container>

    inspHRationGiven

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<inthraction-loading className="wrapper" [initDone]="tableData.data.length > 0"></inthraction-loading>
